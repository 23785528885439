// Libaries
import { faBrush } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@/redux/store";

// Functionality
import { toggleStylingMenu } from "@/redux/slices/pageEditorSlice";

export default function StyleButton() {
  const dispatch = useDispatch();
  const active = useSelector((state: RootState) => state.pageEditorSlice.showStylingMenu);

  return (
    <button
      type="button"
      onClick={() => {
        dispatch(toggleStylingMenu());
      }}
      id="brushStyles"
      className={`bu-flex bu-w-max bu-cursor-pointer bu-rounded  ${active ? "bu-bg-darkgrey" : "bu-bg-lightgrey"}`}
    >
      <FontAwesomeIcon icon={faBrush} className="bu-rotate-[-135deg] bu-rounded bu-px-3 bu-py-2 bu-text-white" />
    </button>
  );
}
