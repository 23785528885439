// Libraries
import { faExpand, faGrid, faGrid2 } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useState } from "react";

// Tile options
const tileOptions = [
  {
    id: 0,
    icon: faGrid,
    name: "grid",
  },
  {
    id: 1,
    icon: faGrid2,
    name: "grid2",
  },
  {
    id: 2,
    icon: faExpand,
    name: "expand",
  },
];

// Component
export default function DisplayMenu({ onIconClick }: { onIconClick: (iconName: string) => void }) {
  const [activeIcon, setActiveIcon] = useState<string>("grid2");

  return (
    <div className="bu-flex bu-w-max bu-rounded bu-bg-offwhite bu-p-1">
      {tileOptions.map((option) => (
        <FontAwesomeIcon
          key={option.id}
          icon={option.icon}
          onClick={() => {
            setActiveIcon(option.name);
            onIconClick(option.name);
          }}
          className={clsx(
            "bu-cursor-pointer bu-rounded bu-px-3 bu-py-2 bu-transition-colors bu-duration-300 hover:bu-text-darkgrey",
            activeIcon === option.name ? "bu-bg-white bu-text-lightgrey" : "bu-bg-offwhite bu-text-slate-400",
          )}
        />
      ))}
    </div>
  );
}
