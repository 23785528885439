// Libraries
import { faRotateLeft, faRotateRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";
import { useDispatch, useSelector } from "react-redux";
// Functionality
import { editModalClosed, editModalOpen } from "@/redux/slices/editModeSlice";
import { toggleEditMode, toggleStylingMenu } from "@/redux/slices/pageEditorSlice";

import { RootState } from "@/redux/store";

// Components
import Preview from "@/components/page-builder/Preview";
import ResponsiveMenu from "@/components/page-builder/topbar/ResponsiveMenu";
import StyleButton from "@/components/page-builder/topbar/StyleButton";
import Button from "@/components/ui/Button";
// import driverObj from "@/driver.js";

function Navbar({ pageName = "", saveAction }: { pageName: string; saveAction: any }) {
  const dispatch = useDispatch();
  const editing = useSelector((state: RootState) => state.pageEditorSlice.editMode);
  const modalState = useSelector((state: RootState) => state.editMode.modal);

  function handleModalState() {
    dispatch(modalState ? editModalClosed() : editModalOpen());
  }

  return (
    <div
      className={clsx(
        "bu-sticky bu-top-0 bu-grow-0 bu-flex bu-items-center bu-justify-between bu-w-full bu-h-20 bu-px-8 bu-py-4 bu-bg-lightgrey bu-z-fixed",
        {
          "bu-rounded-bl-lg": !editing,
        },
      )}
    >
      {/* <Button id="tour" onClick={() => driverObj.drive()}> */}
      {/*    View tour */}
      {/* </Button> */}

      {/* Breadcrumbs + Page state */}
      <div className="bu-flex bu-flex-row bu-items-center bu-gap-3 bu-text-white">
        <span className="bu-text-sm bu-font-bold">{pageName}</span>
        {editing && <span className="bu-text-xs bu-font-medium">(editing)</span>}
      </div>

      {/* Responsive screen size */}
      <div id="responsive">
        <ResponsiveMenu />
      </div>

      {/* Actions outside of edit mode */}
      {!editing && (
        <div className="bu-flex bu-h-7 bu-flex-row bu-items-center bu-gap-5">
          <Preview />
          <div className="bu-h-full bu-border-r-2 bu-border-offwhite bu-opacity-10" />
          <Button onClick={() => dispatch(toggleEditMode(true))} id="edit">
            EDIT
          </Button>
        </div>
      )}

      {/* Only while in Edit mode */}
      {editing && (
        <div className="bu-flex bu-h-7 bu-flex-row bu-items-center bu-gap-5">
          <Button onClick={handleModalState} id="addcomponent">
            Add Component
          </Button>

          {/* Style menu button */}
          <StyleButton />

          {/* Divider */}
          <div className="bu-h-full bu-border-r-2 bu-border-offwhite bu-opacity-10" />

          {/* Undo/redo */}
          <div className="bu-flex bu-gap-2 bu-text-white bu-opacity-50">
            <FontAwesomeIcon icon={faRotateLeft} className="bu-cursor-pointer" />
            <FontAwesomeIcon icon={faRotateRight} className="bu-cursor-pointer" />
          </div>
          <div className="bu-h-full bu-border-r-2 bu-border-offwhite bu-opacity-10" />

          {/* Save */}
          <Button
            id="save"
            theme="green"
            onClick={() => {
              saveAction();
              dispatch(toggleEditMode(false));
              dispatch(toggleStylingMenu(false));
            }}
          >
            SAVE
          </Button>
        </div>
      )}
    </div>
  );
}

export default Navbar;
