// Libaries
import { faDisplay, faMobile, faTablet } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";

import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@/redux/store";

// Functionality
import { setViewport } from "@/redux/slices/pageEditorSlice";

const options = [
  { handle: "desktop", icon: faDisplay },
  { handle: "tablet", icon: faTablet },
  { handle: "mobile", icon: faMobile },
];

export default function ResponsiveMenu() {
  const viewport = useSelector((state: RootState) => state.pageEditorSlice.viewport);
  const dispatch = useDispatch();

  return (
    <div className="bu-flex bu-w-max bu-gap-1 bu-rounded bu-bg-darkgrey bu-p-1">
      {options.map((option) => (
        <FontAwesomeIcon
          key={option.handle}
          icon={option.icon}
          onClick={() => dispatch(setViewport(option.handle))}
          className={clsx(
            "bu-px-3 bu-py-2 bu-text-white bu-rounded bu-cursor-pointer bu-transition-colors bu-duration-300 hover:bu-bg-lightgrey",
            {
              "bu-bg-lightgrey": viewport === option.handle,
              "bu-bg-darkgrey": viewport !== option.handle,
            },
          )}
        />
      ))}
    </div>
  );
}
