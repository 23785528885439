// Libraries
import { useDispatch } from "react-redux";

// Functionality
import { editModalClosed } from "@/redux/slices/editModeSlice";
import { addBlock } from "@/redux/slices/editPageSlice";

// Components
import DynamicComponent from "@/components/content/DynamicComponent";
import { Block, blocks, Component } from "@/lib/blocks";

export default function BlockList({ gridClass }: { gridClass: string }) {
  const dispatch = useDispatch();

  function handleAddBlockClick(block: Component) {
    dispatch(addBlock(block));
    dispatch(editModalClosed());
  }

  return (
    <div
      className={`bu-grid bu-px-5 bu-pb-8 ${gridClass} bu-h-[34rem] bu-w-[65.25rem] bu-gap-8 bu-overflow-auto bu-bg-white`}
    >
      {blocks.map((block: Component) => (
        <div
          key={block.type}
          onClick={() => handleAddBlockClick(block)}
          className="bu-z-10 bu-w-full bu-transform bu-cursor-pointer bu-rounded bu-shadow-xl bu-transition-all bu-duration-500 bu-ease-in-out hover:bu-translate-y-[-10px]"
        >
          <p className="bu-capitalize">{block.type.replaceAll("-", " ")}</p>
          {/* Cast Component type to unknown because types dont overlap  */}
          <DynamicComponent block={block as unknown as Block} />
        </div>
      ))}
    </div>
  );
}
