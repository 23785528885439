/* eslint-disable import/prefer-default-export */
import { AxiosInstance } from "axios";

type TSendResponse = {
  data: any;
  message: string;
  success: boolean;
};

type TDataJson = {
  blocks: any[];
  website_id: string;
  page_id: string;
};

export class SavePage {
  client: AxiosInstance;

  submissionClient: AxiosInstance;

  constructor(client: AxiosInstance, submissionClient: AxiosInstance) {
    this.client = client;
    this.submissionClient = submissionClient;
  }

  sendData(jsonString: TDataJson, pageId: string): Promise<TSendResponse> {
    return this.submissionClient
      .put(`/websites/pages/${pageId}`, jsonString, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response): TSendResponse => response.data)
      .catch((error: Error) => {
        throw error;
      });
  }

  deleteData(pageId: string): Promise<TSendResponse> {
    return this.submissionClient
      .put(`/websites/pages/${pageId}/delete`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response): TSendResponse => response.data)
      .catch((error: Error) => {
        throw error;
      });
  }
}
