import { useState, useEffect } from "react";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { toggleStylingMenu } from "@/redux/slices/pageEditorSlice";
import { RootState } from "@/redux/store";

type TSyleSettings = {
  background: string;
  foreground: string;
  accent: string;
};

// Interfaces / Types
export default function StylingMenu({ apiUrl, pageId }: { apiUrl: string; pageId: string }) {
  const API_ENDPOINT = `${apiUrl}/websites/pages/${pageId}/style`;
  const showStylingMenu = useSelector((state: RootState) => state.pageEditorSlice.showStylingMenu);
  const dispatch = useDispatch();

  const [styleSettings, setStyleSettings] = useState<TSyleSettings>({
    background: "#ffffff",
    foreground: "#000000",
    accent: "#ffffff",
  });

  const handleMenuClick = (menuItem: string) => {
    console.log(`You clicked ${menuItem}`);
  };

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setStyleSettings({
      ...styleSettings,
      [e?.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "attributes" && mutation.attributeName === "style") {
          const getCssVariableValue = (variableName: string) => {
            return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
          };

          const background = getCssVariableValue("--colors-background");
          const foreground = getCssVariableValue("--colors-foreground");
          const accent = getCssVariableValue("--colors-accent");

          setStyleSettings({ background, foreground, accent });
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
    });

    return () => observer.disconnect();
  }, []);

  async function updateStyleSettings(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    document.documentElement.style.setProperty("--colors-background", styleSettings.background);

    document.documentElement.style.setProperty("--colors-foreground", styleSettings.foreground);

    document.documentElement.style.setProperty("--colors-accent", styleSettings.accent);

    try {
      await axios.put(API_ENDPOINT, {
        page_id: pageId,
        styles: {
          background: styleSettings.background,
          foreground: styleSettings.foreground,
          accent: styleSettings.accent,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div
      className={clsx(
        "bu-sticky bu-top-24 bu-z-50 bu-flex bu-flex-col bu-justify-between bu-overflow-hidden bu-bg-darkgrey",
        "bu-transition-all bu-duration-500 bu-ease-in-out",
        "bu-shrink-0 bu-grow-0",
        showStylingMenu ? "bu-w-72 bu-opacity-100" : "bu-w-0 bu-opacity-0",
      )}
      id="style-menu"
    >
      <div className="bu-flex bu-w-72 bu-flex-col bu-pr-4">
        {/* Close menu button */}
        <div className="bu-mb-6 bu-flex bu-items-center bu-self-end">
          <FontAwesomeIcon
            icon={faXmark}
            onClick={() => {
              dispatch(toggleStylingMenu(false));
            }}
            className="bu-cursor-pointer bu-text-xl bu-text-white"
          />
        </div>

        {/* Description */}
        <div className="bu-mb-9 bu-text-white">
          <h3 className="bu-mb-2 bu-text-xl">Website stijlen</h3>
          <p className="bu-text-sm bu-font-medium">Beheer de stijlinstellingen die op uw hele site worden weergeven.</p>
        </div>

        {/* Navigation */}
        <nav className="bu-text-white">
          <ul className="bu-space-y-4 bu-text-base bu-font-medium">
            <li>
              <form onSubmit={updateStyleSettings} className="bu-flex bu-flex-col bu-gap-y-5">
                <div className="bu-flex bu-flex-col bu-gap-y-2">
                  <label htmlFor="background">Background color</label>
                  <input
                    id="background"
                    name="background"
                    type="color"
                    value={styleSettings.background}
                    onChange={handleChange}
                  />
                </div>

                <div className="bu-flex bu-flex-col bu-gap-y-2">
                  <label htmlFor="foreground">Foreground color (text)</label>
                  <input
                    id="foreground"
                    name="foreground"
                    type="color"
                    value={styleSettings.foreground}
                    onChange={handleChange}
                  />
                </div>

                <div className="bu-flex bu-flex-col bu-gap-y-2">
                  <label htmlFor="accent">Accent color</label>
                  <input id="accent" name="accent" type="color" value={styleSettings.accent} onChange={handleChange} />
                </div>

                <button type="submit" className="bu-rounded bu-bg-white bu-px-1 bu-py-3 bu-text-black">
                  Submit
                </button>
              </form>
            </li>

            <li onClick={() => handleMenuClick("Home")} className="bu-cursor-pointer">
              Lettertype
            </li>
            <li onClick={() => handleMenuClick("About")} className="bu-cursor-pointer">
              Kleuren
            </li>
            <li onClick={() => handleMenuClick("Services")} className="bu-cursor-pointer">
              Animaties
            </li>
            <li onClick={() => handleMenuClick("Contact")} className="bu-cursor-pointer">
              Tussenruimtes
            </li>
            <li onClick={() => handleMenuClick("Help")} className="bu-cursor-pointer">
              Buttons
            </li>
          </ul>
        </nav>

        {/* <InfoPanel title="Website ontwerpen">
          <p className="bu-font-light">
            Leer hoe u stijlinstellingen kunt gebruiken om een aangepast
            uiterlijk voor uw project site te creëren.
          </p>
          <a className="" href="">
            Lees meer
          </a>
        </InfoPanel> */}
      </div>
    </div>
  );
}
