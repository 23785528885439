// Styling
import "../../index.css";

// Libraries
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "@/redux/store";
import ApiProvider from "@/providers/apiProviders";
import Editor from "../Editor";

// Components
import { TDefaultProps } from "../../types/default";

// Sentry

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN_TOKEN,
});

const element = document.querySelector(".homy-page-builder");

if (element) {
  const apiUrl = element.getAttribute("data-api-url") || import.meta.env.VITE_BACKEND_API_URL;
  const backendEndpoint = element.getAttribute("data-backend-endpoint") || import.meta.env.VITE_PROJECT_ID;
  const projectId = element.getAttribute("data-project-id") || import.meta.env.VITE_PROJECT_ID;
  const pageId = element.getAttribute("data-page-id") || import.meta.env.VITE_PAGE_ID;
  const websiteId = element.getAttribute("data-website-id") || import.meta.env.VITE_WEBSITE_ID;

  const props: TDefaultProps = {
    apiUrl,
    backendEndpoint,
    projectId,
    page: { id: pageId },
    website: websiteId,
  };

  const root = createRoot(element);

  root.render(
    <Provider store={store}>
      <ApiProvider apiUrl={props.apiUrl} project={props.projectId}>
        <div className="bu-flex bu-w-full bu-items-stretch">
          <main className="bu-relative bu-h-full bu-grow">
            <Editor
              apiUrl={props.apiUrl}
              page={props.page}
              website={props.website}
              backendEndpoint={props.backendEndpoint}
              projectId={props.projectId}
            />
          </main>
        </div>
      </ApiProvider>
    </Provider>,
  );
} else {
  console.error("Er is geen div met .homy-page-builder");
}
