// Library
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import Button from "@/components/ui/Button";

// Component
export default function Preview() {
  return (
    <Button theme="preview" onClick={() => console.log("click")}>
      <FontAwesomeIcon icon={faEye} className="bu-rounded bu-text-white hover:bu-bg-lightgrey" />
      <h6 className="bu-flex bu-items-center bu-text-white">Preview</h6>
    </Button>
  );
}
