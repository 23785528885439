// Libaries
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import BlockList from "@/components/page-builder/add-section/BlockList";
import DisplayMenu from "@/components/page-builder/add-section/DisplayMenu";
import { RootState } from "@/redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import clsx from "clsx";
import { editModalClosed, editModalOpen } from "@/redux/slices/editModeSlice";

export default function AddComponentModal() {
  const modalState = useSelector((state: RootState) => state.editMode.modal);
  const [gridClass, setGridClass] = useState("grid-cols-2");

  const dispatch = useDispatch();

  const handleIconClick = (iconName: string) => {
    switch (iconName) {
      case "grid":
        setGridClass("bu-grid-cols-3");
        break;
      case "grid2":
        setGridClass("bu-grid-cols-2");
        break;
      case "expand":
        setGridClass("bu-grid-cols-1");
        break;
      default:
        setGridClass("bu-grid-cols-1");
        break;
    }
  };

  // Don't show modal if not open
  if (!modalState) return <div />;

  function handleModalState() {
    dispatch(modalState ? editModalClosed() : editModalOpen());
  }

  return (
    <div className="bu-fixed bu-inset-0 bu-z-30 bu-flex bu-cursor-pointer bu-items-center bu-justify-center bu-bg-lightgrey bu-bg-opacity-50">
      <div className="bu-flex bu-overflow-hidden bu-rounded bu-bg-white bu-drop-shadow">
        {/* <Categories /> */}

        <div className="bu-flex bu-flex-col">
          <div>
            <div className="bu-flex bu-w-[65.25rem] bu-justify-between bu-px-12 bu-py-8">
              <div className="bu-flex bu-w-max bu-rounded bu-bg-offwhite bu-p-1">
                <FontAwesomeIcon
                  icon={faClose}
                  onClick={() => {
                    handleModalState();
                  }}
                  className={clsx(
                    "bu-cursor-pointer bu-rounded bu-px-3 bu-py-2 bu-transition-colors bu-duration-300 hover:bu-text-darkgrey",
                    "bu-bg-offwhite bu-text-slate-400",
                  )}
                />
              </div>
              <DisplayMenu onIconClick={handleIconClick} />
            </div>
            <BlockList gridClass={gridClass} />
          </div>
        </div>
      </div>
    </div>
  );
}
