import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import DynamicComponent from "@/components/content/DynamicComponent";
import AddComponentModal from "@/components/page-builder/add-section/AddComponentModal";
import StylingMenu from "@/components/page-builder/styling-panel/Sidebar";
import Navbar from "@/components/page-builder/topbar/Navbar";
import { Block } from "@/lib/blocks";
import { editModalOpen } from "@/redux/slices/editModeSlice";
import { setBlocks } from "@/redux/slices/editPageSlice";
import { RootState } from "@/redux/store";
import { TDefaultProps } from "@/types/default";
import { getPageStyles } from "@/lib/utils";
import { ApiContext } from "@/providers/apiProviders";
import { SavePage } from "@/api/savePage";
// Adds the component/section editor.
// todo: refactor/move to seperate file (SectionEditorComponent.tsx)
// todo: rename (AddSectionButtonComponent?)
function SectionEditor() {
  const dispatch = useDispatch();
  return (
    <div className="bu-relative bu-z-20 bu-hidden bu-opacity-0 bu-outline bu-outline-2 bu-outline-primary bu-transition-all group-hover:bu-block group-hover:bu-opacity-100">
      <button
        type="button"
        className="bu-absolute bu-inset-x-0 bu-m-auto bu-w-fit -bu-translate-y-1/2 bu-rounded bu-bg-primary bu-px-4 bu-py-1 bu-text-white"
        onClick={() => {
          dispatch(editModalOpen());
        }}
      >
        Add section
      </button>
    </div>
  );
}
export default function Editor({ apiUrl, page, website }: TDefaultProps) {
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState<any>();
  const blocks = useSelector((state: RootState) => state.editPageSlice.blocks);
  const editing = useSelector((state: RootState) => state.pageEditorSlice.editMode);
  const viewport = useSelector((state: RootState) => state.pageEditorSlice.viewport);
  const API_ENDPOINT = `${apiUrl}/websites/pages/${page.id}`;
  const { client, submissionClient } = useContext(ApiContext);
  const savePage = new SavePage(client, submissionClient);

  //  Get page styles from DB and applies them to the DOM
  getPageStyles(API_ENDPOINT);

  // Gets page (blocks, styles) data from DB
  const getPageData = async () => {
    try {
      await axios
        .get(API_ENDPOINT)
        .then((response) => {
          dispatch(setBlocks(response.data.blocks));
          setPageData(response.data.page);
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPageData();
  }, []);

  // Posts block data into the DB
  const savePageData = async () => {
    if (blocks.length === 0) {
      savePage
        .deleteData(page.id)
        .then(() => getPageData())
        .catch((error) => console.error(error));
    } else {
      savePage
        .sendData({ blocks, website_id: website, page_id: page.id }, page.id)
        .then(() => getPageData())
        .catch((error) => console.error(error));
    }
  };

  return (
    <>
      <AddComponentModal />
      <div className="bu-flex bu-w-full bu-bg-darkgrey">
        <div className="bu-flex bu-min-h-screen bu-w-full bu-flex-col">
          {/* Topbar */}
          <Navbar pageName={pageData !== undefined ? pageData.name : "Not found"} saveAction={savePageData} />

          <div
            className={clsx("bu-mx-4 bu-flex bu-grow bu-items-start bu-py-4 bu-transition-all bu-duration-500", {
              "bu-gap-x-8": editing,
            })}
          >
            {/* Page */}
            <div
              className={clsx(
                "bu-mx-auto bu-h-max bu-min-h-full bu-w-full bu-rounded bu-bg-white bu-transition-all bu-duration-500 bu-@container/main",
                {
                  "bu-max-w-sm": viewport === "mobile",
                  "bu-max-w-3xl": viewport === "tablet",
                  "bu-max-w-full": viewport === "desktop",
                },
              )}
              id="page-container"
            >
              {[...blocks]
                .sort((a: Block, b: Block) => a.order - b.order)
                .map((block: Block) => {
                  return (
                    <div
                      className={clsx("bu-group bu-relative bu-bg-background bu-text-foreground", {
                        "hover:bu-ring-red": editing,
                      })}
                      key={`editor-field-${block.id}`}
                    >
                      {editing && <SectionEditor />}

                      <DynamicComponent isEditing block={block} />

                      {editing && <SectionEditor />}
                    </div>
                  );
                })}
            </div>

            {/* Styling menu */}
            <StylingMenu apiUrl={apiUrl} pageId={page.id} />
          </div>
        </div>
      </div>
    </>
  );
}
